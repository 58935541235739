import { Brand, BrandDirectory } from '@bff/models/og/brand'

import type { IncomingHttpHeaders } from 'http'


// OG caches it menus response for 10 mins - setting our expiration limit to the same time
export const MENU_CACHE_EXPIRATION = 10 * 60 // 10 mins in seconds

export const DISABLE_BFF_DATA_LOGGING = process.env.DISABLE_BFF_DATA_LOGGING

export const SERVER_API_URL = `${process.env.API_URL}/api` // @TODO rename this to PINE_API_URL
export const GEOLOCALITY_API_URL = `${process.env.GEOLOCALITY_URL}/api`

const dev = process.env.NODE_ENV !== 'production'
export const WWW_BASE_URL = dev ? 'https://localhost:3000' : process.env.WWW_BASE_URL
export const NEXTJS_API_PATH = '/api/v2'

const iOS = /iPad|iPhone|iPod/
const android = /android/i
const webview = /eaze-webview/

const detectUserAgent = (regex: RegExp, headers: IncomingHttpHeaders) => regex.test(headers?.['user-agent'] || '')

export const isIosApp = (headers: IncomingHttpHeaders) =>
  detectUserAgent(iOS, headers) && detectUserAgent(webview, headers)
export const isAndroidApp = (headers: IncomingHttpHeaders) =>
  detectUserAgent(android, headers) && detectUserAgent(webview, headers)
export const isConsumerApp = (headers: IncomingHttpHeaders) => detectUserAgent(webview, headers)

export const VAPORIZER_REGEX = /(vap(orizer|orization|e|or){1}s*\s*)/gi

export const DEFAULT_CHUNK_SIZE = 50

// Title of vaporizer category on iOS webview app for passing anti-vape app reviewers
export const IOS_VAPORIZER_CATEGORY_NAME = 'Cartridges'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const pick = (object: any, keys: string[]): any => {
  return keys.reduce((obj, key) => {
    if (object && Object.prototype.hasOwnProperty.call(object, key)) {
      obj[key] = object[key]
    }
    return obj
  }, {})
}

export const SPLIT_EXPERIMENTS = {
  UPSELL_AT_CART: 'upsell_cart'
}

export const SPLIT_TREATMENTS = {
  UPSELL_AT_CART: {
    ON: 'on',
    OFF: 'off'
  }
}

// Breaks one large array into smaller chunks to send large payloads of data
export function chunkArray(array: any, chunkSize: number) {
  const chunks = []
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }
  return chunks
}

/**
 * Groups a list of brands alphabetically:
 * @param brands - a list of brand objects
 */
export function groupBrands(brands: Brand[]): BrandDirectory[] {
  if (!brands.length) return []

  const letterRanges = [
    { first: 'a', last: 'g', label: 'Brands A - G' },
    { first: 'h', last: 'r', label: 'Brands H - R' },
    { first: 's', last: 'z', label: 'Brands S - #' }
  ]

  // Sort Brands
  const sortedBrands = brands.sort((a, b) => a.name.localeCompare(b.name))

  // Reduce to Group
  const groupedBrands = sortedBrands.reduce((acc, brand) => {
    const firstLetter = brand.name[0]?.toLowerCase();

    // Determine the group for the brand
    let targetRange = letterRanges.find(
      (range) =>
        firstLetter >= range.first && firstLetter <= range.last
    );

    // If no matching range and the first character is a number or special character, use the last range
    const isSpecialOrNumber = /^\d|[^a-z]/i.test(firstLetter);
    if (!targetRange && isSpecialOrNumber) {
      targetRange = letterRanges[letterRanges.length - 1];
    }

    // If target range exists, add the brand to it
    if (targetRange) {
      if (!acc[targetRange.label]) {
        acc[targetRange.label] = { group: targetRange.label, brands: [brand] };
      } else {
        acc[targetRange.label].brands.push(brand);
      }
    }

    return acc;
  }, {} as Record<string, BrandDirectory>)

  // Map letterRanges to preserve order and include custom sort for the last group
  return letterRanges.map((range, index) => {
    const group = groupedBrands[range.label] || { group: range.label, brands: [] };

    // Only sort the last group (S-#) to move numeric brands to the end
    if (index === letterRanges.length - 1) {
      group.brands.sort((a, b) => {
        const isALetter = /^[a-z]/i.test(a.name);
        const isBLetter = /^[a-z]/i.test(b.name);
        const isANumberOrSpecial = /^\d|[^a-z]/i.test(a.name);
        const isBNumberOrSpecial = /^\d|[^a-z]/i.test(b.name);

        // Letters come first
        if (isALetter && !isBLetter) return -1;
        if (!isALetter && isBLetter) return 1;

        // Then numbers and special characters
        if (isANumberOrSpecial && !isBNumberOrSpecial) return 1;
        if (!isANumberOrSpecial && isBNumberOrSpecial) return -1;

        // Alphabetical order within each group
        return a.name.localeCompare(b.name);
      });
    }

    return group;
  });
}
